<template>
	<div class="wrap">
		<div class="header">D音获客</div>
		<div class="tableOperate">
			<el-button type="primary" icon="el-icon-refresh" @click="reloadPage()"></el-button>
			<el-button type="primary" plain @click="add">添加</el-button>
		</div>
		<div class="content">
			<el-table :data="newList" style="width: 100%" stripe>
				<el-table-column type="selection"></el-table-column>
				<el-table-column label="任务id" width="150" st3le="margin-left: 30px;">
					<template slot-scope="scope">
						{{scope.row.id}}
					</template>
				</el-table-column>
				<el-table-column label="任务名" width="140">
					<template slot-scope="scope">
						{{scope.row.task_name}}
					</template>
				</el-table-column>
				<el-table-column label="任务状态" width="140">
					<template slot-scope="scope" >
						<div class="box" v-if="scope.row.status == 1">
							运行中
						</div>
						<div class="box" v-if="scope.row.status == 0">
							已完成
						</div>
						<div class="box" v-if="scope.row.status == 2">
							已暂停
						</div>
						<div class="box" v-if="scope.row.status == 3">
							用户删除
						</div>
					</template>
				</el-table-column>
				<el-table-column label="采集源" width="200">
					<template slot-scope="scope">
						{{scope.row.collection_source}}
					</template>
				</el-table-column>
				<!-- <el-table-column label="任务状态" width="150">
					<template slot-scope="scope">
						{{scope.row.zt}}
					</template>
				</el-table-column> -->
				<el-table-column label="时间筛选" width="150">
					<template slot-scope="scope">
						<div v-if="scope.row.time_filter == 0">
							全部
						</div>
						<div v-if="scope.row.time_filter == 1">
							一天
						</div>
						<div v-if="scope.row.time_filter == 7">
							一周
						</div>
						<div v-if="scope.row.time_filter == 15">
							半月
						</div>
						<div v-if="scope.row.time_filter == 30">
							一月
						</div>
					</template>
				</el-table-column>
				<el-table-column label="意向客户数" width="130">
					<template slot-scope="scope">
						{{scope.row.intended_count}}
					</template>
				</el-table-column>
				<el-table-column label="线索视频数" width="130">
					<template slot-scope="scope">
						{{scope.row.clue_videos_count}}
					</template>
				</el-table-column>
				<el-table-column label="创建时间" width="260">
					<template slot-scope="scope">
						{{scope.row.time}}
					</template>
				</el-table-column>
				<el-table-column label="操作" >
					<template slot-scope="scope">
						<el-button type="text" size="small" @click="jumpCusto(scope.row)">客户</el-button>
						<el-button type="text" size="small" @click="jumpVideo(scope.row)">视频</el-button>
						<el-button type="text" size="small" @click="jumpModify(scope.row)">修改</el-button>
						<el-button type="text" size="small" @click="delList(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div class="footer">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:page-sizes="[10, 20, 30]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					:current-page.sync="currentPage" :total="total" background>
				</el-pagination>
			</div>
		</div>
		<el-dialog title="添加" :visible.sync="dialogVisible" width="40%" :before-close="handleClose" :destroy-on-close="true" >
			<el-form :model="form" label-width="120px" style="width: 80%;margin: 0 auto;"  ref="form" :rules="rules">
				<el-form-item label="任务名" prop="task_name">
					<el-input  v-model="form.task_name"  placeholder="请输入任务名称"> </el-input>
				</el-form-item>
				<el-form-item label="采集源" prop="collection_source">
					<el-input v-model="form.collection_source" placeholder="请输入采集源"></el-input>
				</el-form-item>
				<el-form-item label="评论筛选关键词"  prop="comment_keywords">
					<el-input type="textarea" v-model="form.comment_keywords" placeholder="请输入评论筛选关键词"></el-input>
					<div class="text">
						<p>系统推荐关键词（点击关键词快速添加到词库）</p>
						<div class="text_bigBox">
							<span v-for="(k,index) in cikuArr" :key="index" @click="addCiKu(k.id)">{{k.title}}</span>
						</div>
					</div>
				</el-form-item>
				
				<!-- <el-form-item label="消耗积分" class="xhjf"  prop="points_consumed_max">
					<el-input style="margin-left: 0;" v-model="form.points_consumed_max" placeholder="消耗积分至少为10积分"></el-input>分
				</el-form-item> -->
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="addForm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import Axios from 'axios'
	export default {
		data() {
			return {
				newList: [],
				dialogVisible: false,
				form:{
					task_name:"",
					collection_source:'',
					comment_keywords:'',
					// points_consumed_max:'',
					seller_id:this.$store.state.SELLER_ID,
				},
				cikuArr:[
					{id:1,title:'多少钱'},
					{id:2,title:'价格'},
					{id:3,title:'厂家'},
					{id:4,title:'哪里'},
					{id:5,title:'地址'},
					{id:6,title:'联系'},
					{id:7,title:'电话'},
					{id:8,title:'购买'},
					{id:9,title:'怎么买'},
					{id:10,title:'怎么卖'},
					{id:11,title:'售卖'},
					{id:12,title:'采购'},
					{id:13,title:'感兴趣'},
					{id:14,title:'有兴趣'},
					{id:15,title:'合作'},
					{id:16,title:'喜欢'},
					{id:17,title:'咋买'},
					{id:18,title:'咋卖'},
					{id:19,title:'下单'},
					{id:20,title:'怎么'},
				],
				pageNum:"", //
				total: 0, //数据总数
				pageSize: 10, //每页显示数量
				currentPage: 1, //当前页数
				p:0 ,  //总页数
				rules: {
					task_name: [{
						required: true,
						message: '请输入任务名'
					}],
					collection_source: [{
						required: true,
						message: '请输入采集源'
					}],
					comment_keywords:[{
						required: true,
						message: '请输入筛选关键词'
					}],
					// points_consumed_max: [{
					// 	required: true,
					// 	validator: (rule, value, callback) => {
					// 		var reg = /^[1-9]\d{1,4}$/
							
					// 		if (Number(value) < 10) {
					// 			callback(new Error('至少消耗10积分'));
					// 		} else if(isNaN(Number(value))){
					// 			callback(new Error('只能填写数字'));
					// 		}else {
					// 			callback();
					// 		}
					// 	}
					// }]
				},
			}
		},
		created() {
			this.getList()
			// console.log("SELLER_ID",this.$store.state.SELLER_ID)
		},
		methods: {
			addCiKu(id){
				let cikuArr = this.cikuArr
				cikuArr.map(item => {
					if(item.id == id){
						this.form.comment_keywords += (item.title+',')
					}
				})
			},
			add(){
				this.form = {
					task_name:"",
					collection_source:'',
					comment_keywords:'',
					seller_id:this.$store.state.SELLER_ID,
				}
				this.dialogVisible = true
			},
			reloadPage(){   // 刷新页面
				this.getList()
			},
			addForm(done){
				  this.$refs['form'].validate((valid) => {
					   if (valid) {
						  this.$axios.post('/saveTaskManager',this.form).then(data => {
						  	if(data.code == '0000'){
						  		 this.$message.success(data.msg)
						  		 this.dialogVisible = false
								 this.getList()
						  	}else{
						  		this.$message.error(data.msg)
						  		this.dialogVisible = false
						  	}
						  }) 
					   }else{
						   console.log('error submit!!');
						   return false;
					   }
					  
				  })
				
			},
			// 分页器
			handleSizeChange(val) {
				this.pageSize = val
				this.pageNum=val
				this.getList()
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.p =val
				this.getList()
			},
			// handleSizeChange(val) {
			// 	this.pageSize = val
			// 	this.getList(this.pageSize,this.currentPage)
			// },
			// handleCurrentChange(val) {
			// 	this.currentPage = val
			// 	this.getList(this.pageSize,this.currentPage)
			// },
			jumpCusto(row) {
				this.$router.push({path:`/tiktok/customer`,query:{task_id:row.id}});
			},
			jumpVideo(row) {
				this.$router.push({path:`/tiktok/video`,query:{task_id:row.id}});
			},
			jumpModify(row) {
				this.dialogVisible = true
				this.form = row
			},
			delList(row){
				this.$confirm('此操作将永久删除产品, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios.post('/delTask',{seller_id:this.$store.state.SELLER_ID,task_id:row.id}).then(data => {
						if(data.code == "0000"){
							this.$message.success(data.msg)
							this.getList()
						}else{
							this.$message.error(data.msg)
						}
					})
					
				}).catch()
			},
			getList() {
				this.$axios.post('/getTaskList',{
					seller_id:this.$store.state.SELLER_ID,
					limit:this.pageSize,
					page:this.currentPage,
				}).then(data => {
						this.newList = data.data
					if(data.count){
						this.total = data.count 
						  // 总条数
						// this.p =Math.ceil( data.count / pageSize)   //总页数
					}
					// var start = (currentPage - 1) * pageSize   //开始位置 当前页数 *条数
					// var end = currentPage * pageSize    //结束位置
					// var datas = data.data
					// var c = datas.slice(start,end)
					// this.newList = c
				})
			},
			handleClose(done){
				done()
			}
		}
	}
</script>

<style scoped>
	.wrap{
		width: 1600px;
		background-color: #fff;
	}
	body{
	  min-width:1920px;
	}
	.ships {
		font-size: 12px;
	}

	.header {
		padding: 0 28px;
		height: 77px;
		border-bottom: 1px solid #e0e0e0;
		display: flex;
		font-size: 18px;
		color: #000000;
		align-items: center;
		font-weight: 700;
	}

	.header::before {
		content: '';
		width: 4px;
		height: 20px;
		background: #0e69db;
		margin-right: 10px;
	}

	.content {
		padding: 0 28px;
	}

	.tip {
		padding: 21px 32px;
		font-size: 14px;
		color: #666666;
		background: #ecf4ff;
		border-radius: 8px;
	}

	.tip p {
		height: 27px;
		line-height: 27px;
		display: flex;
	}

	.tip a {
		padding: 0 11px;
		height: 26px;
		line-height: 26px;
		color: #fff;
		background: #0194fe;
		border-radius: 5px;
		margin-left: 30px;
	}

	.screen {
		margin-top: 36px;
	}

	.wrap>>>.el-table th {
		background: #e3e4e7;
		color: #120500;
	}

	.tableOperate {
		margin: 10px 0px 10px 30px;
	}

	.tableOperate .el-button {
		margin-right: 20px;
	}

	.bottomChecked {
		margin: 0 14px;
	}

	.xhjf {
		display: flex;
	}
	.xhjf>>>.el-form-item__content{
		margin-left: 0px !important;
		display: flex !important;
	}
	.xhjf .el-input{
		margin-right: 10px;
	}
	.xhjf input {
		width: 200px;
	}

	.botBtn {
		width: 686px;
		display: flex;
		justify-content: space-around;
	}

	.footer {
		padding: 75px 0 86px;
		text-align: center;
	}

	.footer>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background: #ff4344;
	}

	.footer>>>.el-pagination.is-background .el-pager li:not(.disabled):hover {
		color: #C0C4CC;
	}

	.stars {
		width: 15px;
		height: 15px;
		background: url(../../assets/img/stars.png) no-repeat;
		background-size: 100% 100%;
	}

	.tit-wrap {
		display: flex;
	}

	.tit-img {
		width: 58px;
		height: 58px;
		border: 1PX solid #eeeeee;
		flex: none;
	}

	.tit-img img {
		width: 100%;
		height: 100%;
	}

	.tit-info {
		margin-left: 7px;
		align-items: center;
		font-size: 12px;
		overflow: hidden;
	}

	.wrap .tit-name {
		display: flex;
		height: 20px;
		line-height: 20px;
		align-items: center;
		color: #120500;
	}

	.tit-name span {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.tit-name i {
		margin-left: 10px;
		font-size: 14px;
		cursor: pointer;
	}

	.wrap .tit-num {
		height: 20px;
		line-height: 20px;
		color: #666666;
		display: flex;
	}

	.tit-num span {
		margin-right: 18px;
	}

	.wrap .tit-qr {
		display: flex;
		height: 19px;
		line-height: 19px;
		align-items: center;
		cursor: pointer;
	}

	.icon-qr {
		width: 12px;
		height: 12px;
		background: url(../../assets/img/icon-qr.png);
		background-size: 100%;
	}

	.tit-qr span {
		color: #666666;
		margin-left: 5px;
	}

	.share-qr {
		width: 130px;
		height: 130px;
		margin: 10px auto;
	}

	.share-qr img {
		width: 100%;
		height: 100%;
	}

	.edit-name-form .el-form-item--mini.el-form-item,
	.el-form-item--small.el-form-item {
		margin-bottom: 0;
	}

	.money-wrap {
		/* display: flex; */
		/* align-items: center; */
	}

	.money-wrap i {
		margin-left: 5px;
		cursor: pointer;
	}

	.add-btn {
		margin: 15px 0;
	}

	.zk {
		width: 760px;
		height: 360px;
		position: fixed;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 1px 1px 15px #ccc;
		z-index: 5;
		display: none;
	}

	.boxSpan {
		padding: 10px 0;
	}

	.zhekou {
		margin-top: 30px;
	}

	.logist_btn {
		/* display: flex;
		justify-content: space-around; */
		text-align: center;
	}

	.boxForm {
		margin-top: 30px;
	}

	.inpkuan {
		width: 123px;
	}

	.listbox {
		display: inline-block;
		padding: 20px 0;
	}

	.zkboxAdd {
		text-align: center;
	}

	.title {
		font-size: 16px;
		display: block;
		text-align: left;
		margin-left: -30px;
	}

	.inp {
		width: 50px;
	}

	.bttnn {
		padding: 13px 22px;
		font-size: 14px;
	}
	.footer {
		padding: 75px 0 86px;
		text-align: center;
	}
	
	.footer>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background: #ff4344;
	}
	
	.footer>>>.el-pagination.is-background .el-pager li:not(.disabled):hover {
		color: #C0C4CC;
	}
	.text{
		
	}
	.text_bigBox{
		display: flex;
		flex-wrap: wrap;
	}
	.text_bigBox span{
		margin:8px 10px 0 0;
		background-color:#69a8eb;
		color: #fff;
		display: inline-block;
		padding: 0px 13px;
	}
	.el-table th>.cell{
		text-align: center;
	}
</style>
